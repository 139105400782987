/* eslint-disable */
import { graphql, StaticQuery } from 'gatsby';
import React from 'react';

import { Button } from '../components/atoms';
import { DefaultLayout } from '../components/layout';
import { CaseTeaser, Teaser } from '../components/molecules';
import { TeaserList } from '../components/organisms';
import { replaceCount } from '../utils/count';

// ✅ Import the refined Retro Terminal Component
import { StageRetro } from '../components/molecules/stage-retro';

const teasers = [
  {
    title: 'Effizient',
    subline: 'Mehr Zeit für das Wesentliche',
    description:
      'Optimieren Sie Ihre Geschäftsprozesse und sparen Sie wertvolle Zeit mit unseren KI-Lösungen. Starten Sie noch heute durch.',
    link: '/leistungen',
    linkText: 'Mehr über unsere Lösungen',
  },
  {
    title: 'Zukunftsorientiert',
    subline: 'Heute handeln, morgen profitieren',
    description:
      'Mit Technologien wie Chatbots und Automatisierung schaffen wir innovative Lösungen, die Ihr Unternehmen nachhaltig stärken.',
    link: '/projekte',
    linkText: 'Unsere Projekte ansehen',
  },
  {
    title: 'Partnerschaftlich',
    subline: 'Gemeinsam nach vorne gehen',
    description:
      'Wir begleiten Sie mit persönlicher Beratung und individuellen Lösungen auf Ihrem Weg zur Digitalisierung. Gemeinsam entwickeln wir Strategien.',
    link: '/kontakt',
    linkText: 'Kontaktieren Sie uns',
  },
];

// ✅ Improved GraphQL Query for More Reliable Data Fetching
const pageQuery = graphql`
  {
    allStagesJson(filter: { siteTitle: { eq: "Index" } }) {
      edges {
        node {
          id
          siteTitle
          siteDescription
          title
          contentBlocks {
            id
            value
          }
          link
          linkText
        }
      }
    }
    # 🔹 More precise query for CaseTeaser image
    allImageSharp(filter: { original: { src: { regex: "/sanitaer_chat/" } } }) {
      edges {
        node {
          id
          gatsbyImageData(
            width: 800
            quality: 92
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
    }
  }
`;

const Index = (location) => (
  <StaticQuery
    query={pageQuery}
    render={({ allStagesJson, allImageSharp }) => {
      // console.log("🔍 Debugging: Raw GraphQL Data", { allStagesJson, allImageSharp });

      // ✅ Ensure Stages data is available
      if (!allStagesJson.edges.length) {
        console.error("❌ Error: No stage data found!");
        return null;
      }

      const stageNode = allStagesJson.edges[0].node;
      const { title, contentBlocks, link, linkText } = stageNode;

      // ✅ Ensure CaseTeaser image exists
      const caseImage = allImageSharp.edges.length > 0 
        ? allImageSharp.edges[0].node.gatsbyImageData 
        : null;

      return (
        <DefaultLayout
          siteTitle="Softwareentwicklung und KI-Lösungen | DeepReturn"
          siteDescription={
            'DeepReturn bietet massgeschneiderte Softwareentwicklung und KI-Lösungen für Unternehmen. ' +
            'Entdecken Sie unsere innovativen Projekte und erfahren Sie, wie wir Ihr Unternehmen unterstützen können.'
          }
          location={location}
        >
          {/* 🔥 NEW: Improved Synthwave-style AI Terminal replacing the Stage component */}
          <StageRetro
            modifiers={['landing-page', 'left-highlighted']}
            title={<h1 dangerouslySetInnerHTML={{ __html: title || 'DeepReturn AI Solutions' }} />}
            placeholders={[
              'Initializing AI Interface...',
              'Optimizing Business with AI...',
              'Chatbots & Automation at Scale...',
              'Welcome to the Future of Intelligent Software...',
            ]}
          >
            {/* Keep the original content inside the text column */}
            {contentBlocks.map(({ id, value }) => (
              <p key={id}>{replaceCount(value, 2)}</p>
            ))}
            <Button url={link} text={linkText || 'Mehr Erfahren'} isPrimary />
          </StageRetro>

          {/* ✅ Teasers List */}
          <TeaserList>
            {teasers.map((teaser) => (
              <Teaser key={teaser.title} title={teaser.title} subline={teaser.subline}>
                <p>{teaser.description}</p>
                <Button url={teaser.link} text={teaser.linkText} isPrimary hasBorder />
              </Teaser>
            ))}
          </TeaserList>

          {/* ✅ CaseTeaser Section */}
          <CaseTeaser
            modifiers={['right-highlighted', 'image-padded']}
            url="/cases/chatbots"
            title="KI-Chatbots"
            subline="Ihr persönlicher digitaler Agent"
            image={{
              gatsbyImageData: caseImage || null,
              alt: 'Archiv',
            }}
          >
            <p>
              Unsere KI-Chatbots beantworten rund um die Uhr Kundenfragen, fassen Anfragen zusammen 
              und leiten sie an Menschen weiter, damit Sie sich auf das Wesentliche konzentrieren können. 
              Mit einer integrierten Produktdatenbank kennt der Chatbot jedes Detail – wie ein Agent, der 
              sich nur auf Ihr Unternehmen spezialisiert. Intelligente Automatisierung für Effizienz und 
              beste Kundenerfahrung.
            </p>
          </CaseTeaser>
        </DefaultLayout>
      );
    }}
  />
);

export default Index;
